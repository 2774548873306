@font-face {
    font-family: NothingYouCouldDo;
    src: url('../../assets/NothingYouCouldDo-Regular.ttf');
    fallback: swap;
}

.bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
}

.f5 {
    padding: 10px 0 0 18px;
    font-family: NothingYouCouldDo, cursive;
    color: yellow;
    font-size: 2.3em;
}

.footer {
    color: grey;
}

.row {
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

[class*="col-"] {
    float: left;
    padding: 15px;
}

.col-1 {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9em;
    color: orange;
}

.col-2 {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    color: grey;
}

.col-3 {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: orange;
}

.icon {
    font-size: 23px;
}

.email {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    font-size: 1.2em;
}

.name {
    color: orange;
}

.type {
    font-size: 1.2em;
}